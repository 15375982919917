<template>
  <div id="filter-form">
    <el-form
      ref="filter-form"
      :model="{ data }"
      label-width="50px"
    >
      <multi-field-update
        :data="data"
        :fields="fieldConfig"
      />
      <div class="form-controls">
        <el-button @click="reset">
          Reset
        </el-button>
        <el-button type="primary" @click="submit">
          Confirm
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
/**
 * FilterForm
 *
 * Prompts users to select fields to filter on. For each
 * filter, an input will be displayed which allows the
 * user to give the value for that filter.
 *
 * Events:
 *
 * @close: close the modal
 * @reset: reset filters
 * @submit: send form values to parent
 */
import MultiFieldUpdate from '@/components/forms/MultiFieldUpdate'
import fieldConfig from './_fieldConfig'

export default {
  name: 'UpdateForm',
  components: {
    'multi-field-update': MultiFieldUpdate
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  created () {
    this.fieldConfig = fieldConfig(this)
  },
  methods: {
    /**
     * onClick handler for submit button.
     *
     * Emits 'submit' event if form data is valid.
     */
    submit () {
      this.$refs['filter-form'].validate(valid => {
        if (!valid) {
          return
        }
        // Send a copy, reset() will clear existing data
        this.$emit('submit', this.data)
        this.$emit('close')
      })
    },
    /**
     * onClick handler for reset button.
     *
     * Clears form validation and emits events.
     */
    reset () {
      this.$refs['filter-form'].clearValidate()
      this.$emit('reset')
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.form-controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}
</style>
