import { constants } from '@/utils/constants'

function getOptions (source) {
  // Source must be an array of objects with the keys "label" and "value" set
  const options = []
  for (const item of source) {
    options.push({
      component: 'el-option',
      options: {
        props: item
      }
    })
  }
  return options
}

/**
 * Schema definition.
 *
 * The calling component should pass its context
 * object so that we can use it for validation.
 *
 * @param {Object} vm - component instance
 * @param {Array} vm.data - field/value pairs
 * @returns {Object}
 */
export default function fieldConfig (vm) {
  return [
    {
      label: 'Status',
      value: 'status',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true,
            clearable: true
          }
        },
        children: getOptions(constants.status)
      }
    },
    {
      label: 'URL',
      value: 'url',
      render: {
        component: 'el-input'
      },
      rules: {
        type: 'url', message: 'Please provide a valid URL', trigger: 'blur'
      }
    },
    {
      label: 'Product',
      value: 'product',
      render: {
        component: 'el-select',
        children: getOptions(vm.$store.state.products.map(el => ({ label: el.name, value: el.name })))
      }
    },
    {
      label: 'Feature',
      value: 'feature',
      render: {
        component: 'el-select',
        children: getOptions(vm.$store.state.features.map(el => ({ label: el.name, value: el.name })))
      }
    },
    {
      label: 'State',
      value: 'state',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true,
            clearable: true
          }
        },
        children: getOptions(constants.states.concat(constants.provinces))
      }
    },
    {
      label: 'City',
      value: 'city',
      render: {
        component: 'el-input'
      }
    },
    {
      label: 'Street',
      value: 'street',
      render: {
        component: 'el-input'
      }
    },
    {
      label: 'Postal Code',
      value: 'postal',
      render: {
        component: 'el-input'
      }
    },
    {
      label: 'Country',
      value: 'country',
      render: {
        component: 'el-input'
      }
    }
  ]
}
