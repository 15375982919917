<template>
  <div id="property-search">
    <h2>
      Property Search {{ properties.length > 0 ? `(${properties.length})`: '' }}
    </h2>
    <property-search-input
      :loading="loading"
      @search="search"
    />
    <property-search-results
      :data="properties"
      :loading="loading"
    />
  </div>
</template>

<script>
import PropertySearchInput from './_components/PropertySearchInput'
import PropertySearchResults from './_components/PropertySearchResults'
import CraigslistAPI from '@/services/api/craigslist'

export default {
  name: 'PropertySearch',
  components: {
    'property-search-input': PropertySearchInput,
    'property-search-results': PropertySearchResults
  },
  data () {
    return {
      properties: [],
      loading: false
    }
  },
  methods: {
    /**
     * Fetch property data from the API.
     *
     * @param {Object} params - query params
     */
    async search (params) {
      try {
        this.loading = true
        this.properties = await CraigslistAPI.properties.list(params)
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
h2 {
  margin-top: 0;
}
</style>
