<template>
  <div id="property-search-input">
    <el-form
      ref="form"
      :inline="true"
      :model="basicFilters"
      @submit.native.prevent
    >
      <el-form-item prop="company" class="company-select">
        <el-select
          v-model="basicFilters.company"
          placeholder="All Companies"
          size="medium"
          filterable
          clearable
        >
          <el-option
            v-for="company in companies"
            :key="company.value"
            :label="company.label"
            :value="company.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="searchValue">
        <el-input
          v-model="basicFilters.searchValue"
          placeholder="Property Name"
          size="medium"
          clearable
          @keyup.enter.native="search"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="success"
          icon="el-icon-search"
          size="medium"
          @click="search"
        >
          Search
        </el-button>
      </el-form-item>
    </el-form>

    <div>
      <el-button
        :type="activeFilters ? 'primary' : 'info'"
        size="mini"
        icon="el-icon-set-up"
        @click="showAdvancedFilters = true"
      >
        Add filters
      </el-button>
      <el-button
        v-if="activeFilters"
        type="text"
        size="mini"
        @click="resetFilters"
      >
        Reset
      </el-button>
    </div>

    <form-dialog
      :show="showAdvancedFilters"
      title="Add Filters"
      width="620px"
    >
      <filter-form
        :data="advancedFilters"
        @close="showAdvancedFilters = false"
        @reset="resetFilters"
        @submit="handleFilterChange"
      />
    </form-dialog>
  </div>
</template>

<script>
import FormDialog from '@/components/dialogs/FormDialog'
import FilterForm from './FilterForm'

export default {
  name: 'PropertySearchDialog',
  components: {
    'form-dialog': FormDialog,
    'filter-form': FilterForm
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      basicFilters: {
        company: '',
        searchValue: ''
      },
      advancedFilters: [{
        field: null,
        value: null
      }],
      showAdvancedFilters: false
    }
  },
  computed: {
    companies () {
      return this.$store.state.companies.data.map(company => {
        return {
          label: company.human_name,
          value: company.name
        }
      })
    },
    activeFilters () {
      return this.advancedFilters.some(el => el.value)
    }
  },
  created () {
    // restore filters from route query
    const query = this.$route.query
    const keys = Object.keys(query)
    if (keys.length > 0) {
      for (const key of keys) {
        if (key === 'search') {
          this.basicFilters.searchValue = query[key]
        } else if (key === 'group') {
          this.basicFilters.company = query[key]
        } else {
          this.advancedFilters.push({ field: key, value: query[key] })
        }
      }
      this.search()
    }
  },
  methods: {
    /**
     * Validate form and emit search event for parent to handle.
     */
    search () {
      const params = new URLSearchParams()
      if (this.basicFilters.searchValue) {
        params.append('search', this.basicFilters.searchValue)
      }
      if (this.basicFilters.company) {
        params.append('group', this.basicFilters.company)
      }
      for (const filter of this.advancedFilters) {
        if (filter.value) {
          params.append(filter.field, filter.value)
        }
      }

      // save search filters as route query
      const query = {}
      params.forEach((value, key) => (query[key] = value))
      this.$router.replace({ path: this.$route.path, query: query }).catch(err => err)

      this.$emit('search', params)
    },
    /**
     * onSubmit handler for advanced filter change.
     *
     * Auto-removes any filters which are empty.
     */
    handleFilterChange () {
      this.advancedFilters = this.advancedFilters.filter(el => el.value)
      // We need at least one element in advancedFilter list
      // in order for MultiFieldUpdate to render something
      if (!this.advancedFilters.length) {
        this.resetFilters()
      }
      this.search()
    },
    /**
     * Reset the advanced filters.
     */
    resetFilters () {
      this.advancedFilters = [{
        field: null,
        value: null
      }]
    }
  }
}
</script>

<style scoped>
.el-input {
  width: 300px;
}
.company-select .el-select {
  width: 250px;
}
</style>
