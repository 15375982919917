<template>
  <div id="property-search-results">
    <data-table
      v-loading="loading"
      :data="properties"
      :table-attrs="tableAttrs"
    >
      <el-table-column
        label="Name"
        prop="property.name"
        width="400px"
        :sortable="true"
      >
        <template slot-scope="scope">
          <link-button
            type="primary"
            :to="getPropertyEditRoute(scope.row)"
          >
            {{ scope.row.property.name }}
          </link-button>
        </template>
      </el-table-column>

      <el-table-column
        label="Company"
        prop="property.company"
        width="300px"
        :sortable="true"
      >
        <template slot-scope="scope">
          <link-button
            type="primary"
            :to="{ name: 'CompanyOverview', params: { cid: scope.row.company.id } }"
          >
            {{ scope.row.company.human_name }}
          </link-button>
        </template>
      </el-table-column>

      <el-table-column
        label="Property Email"
        prop="property.email_contact"
        :sortable="true"
      />

      <el-table-column
        label="Status"
        prop="property.status"
        :sortable="true"
      />
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/tables/DataTable'
import LinkButton from '@/components/buttons/LinkButton'

export default {
  name: 'PropertySearchResults',
  components: {
    'data-table': DataTable,
    'link-button': LinkButton
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    properties () {
      // Add company obj to each property
      return this.data.map(prop => {
        prop.company = this.getCompany(prop)
        return prop
      })
    }
  },
  created () {
    this.tableAttrs = {
      showHeader: true,
      stripe: true,
      border: true
    }
  },
  methods: {
    /**
     * Given a property, return the company.
     *
     * @param {Object} property
     * @returns {Object}
     */
    getCompany (property) {
      return this.$store.getters.getCompanyById(property.property.company)
    },
    /**
     * Return route to the property edit view.
     *
     * @param {Object} row - row data
     * @returns {Object}
     */
    getPropertyEditRoute (row) {
      const params = {
        cid: row.property.company,
        id: row.property.id
      }
      return { name: 'PropertyEdit', params }
    }
  }
}
</script>
